@import "bootstrap/dist/css/bootstrap.min.css";
@import "../../node_modules/bootstrap";

:root {
  /* colors */
  --blue-btn: linear-gradient(270deg, #50CAFF 0%, #0478FF 100%);
  --default-btn: #ffffff;

  //body
  --bs-body-color: var(--text-primary);
  --bs-body-bg: var(--background);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  //--bs-body-line-height: 1.6em;

  --offcanvas: rgba(111, 126, 140, 0.88); /* выезжающее меню */
  --border-l: #fff;

  /* shadows */
  --shadow-l-btn: 2px 2px 4px rgba(114, 142, 171, 0.1),
  -6px -6px 20px #FFFFFF,
  4px 4px 20px rgba(111, 140, 176, 0.41),
  inset -4px -4px 9px rgba(255, 255, 255, 0.88),
  inset 4px 4px 14px #C1D5EE;
  --shadow-l-btn-h: inset -4px -4px 9px rgba(202, 224, 255, 0.88), inset 4px 4px 14px #C1D5EE;

  --shadow-default-btn: 2px 2px 4px rgba(114, 142, 171, 0.1),
  -6px -6px 20px #FFFFFF,
  4px 4px 20px rgba(111, 140, 176, 0.41);

  --shadow-l-card: 2px 2px 4px rgba(114, 142, 171, 0.1),
  -6px -6px 20px #FFFFFF,
  4px 4px 20px rgba(111, 140, 176, 0.41);

  /* sizes */
  --text-xs: 16px;
  --text-m: 18px;

  --offset-scroll-chips: 110px;
  --offset-floating-switch-btn: calc(var(--offset-scroll-chips) + 56px);

  @media screen and (max-width: 992px) {
    --offset-scroll-chips: 75px;
    --offset-floating-switch-btn: calc(var(--offset-scroll-chips) + 56px)
  }

  @media screen and (max-width: 960px) {
    --offset-scroll-chips: 76px;
    --offset-floating-switch-btn: calc(var(--offset-scroll-chips) + 56px)
  }

  @media screen and (max-width: 576px) {
    --offset-floating-switch-btn: 70px;
  }
}

/* system */

body {
  margin: 50px auto 50px;
}

hr {

}

.card {
  background-color: var(--background-color);
  /*box-shadow: var(--shadow-l-card);*/
  box-shadow: 0 0 14px -5px rgba(132, 132, 132, 0.4);
  border-radius: var(--br);
  border: none !important;
}

.bg-default {
  background-color: var(--default-btn);
}

.bg-blue {
  background-color: var(--blue-btn);
}

.bg-buy {
  background-color: var(--buy-btn);
}

.offcanvas {
  color: var(--text-primary);
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.25);

  .offcanvas-header {
    .offcanvas-title{
      font-size: 2rem;
      font-weight: var(--bold);
    }
  }

  .offcanvas-footer {
    padding: 1rem;
  }

  @media screen and (max-width: 720px) {
      min-width: -webkit-fill-available;
  }
}

.auth_form {
  background: var(--background-color);
  box-shadow: 2px 2px 4px rgba(114, 142, 171, 0.1), -6px -6px 20px #FFFFFF, 4px 4px 20px rgba(111, 140, 176, 0.41);
  border-radius: 8px;
}

.auth_form form > div {
  margin-bottom: 20px;
}

.hideBlock {

}

.mobileBlock {
  display: none;
  overflow: hidden;
  object-fit: cover;
  border-radius: var(--br);
}

.mobileBlock img {
  width: 100%;
}

@media screen and (max-width: 760px) {
  .mobileBlock {
    display: block;
  }
}

.pointer {
  cursor: pointer !important;
}

.blocked {
  cursor: wait !important;
  background-color: rgba(72, 72, 72, 0.1);
}

.offcanvas-backdrop {
  width: 100%;
  height: 100%;
}

.offcanvas-body {
  font-size: 1.2rem;
}

.fade.modal-backdrop.show {
  width: 100%;
  height: 100%;
}

.wrapperResponseDevice {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: .5rem;
  justify-content: space-between;
  align-items: center;
}

.bsGutterY {
  --bs-gutter-y: calc(var(--unit) * 4);
}

.row {
  --bs-gutter-x: calc(var(--unit) * 4);
}

.br {
  border-radius: var(--br);
}

.errorValidateBlock {
  color: var(--error);
}

.accordion {
  font-size: 1rem;
  font-weight: var(--medium) !important;
  --bs-accordion-bg: #ffff;
}

.accordion-button {
  &::after {
    color: var(--primary) !important;
  }
}

.accordion-header button {
  font-weight: var(--medium) !important;
}

.disableElem {
  pointer-events: none;
  opacity: .8;
}

/*override styles*/
.form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}

.flex-1 {
  flex: 1;
}

.blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  //backdrop-filter: blur(8px);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px) !important;
  backdrop-filter: blur(30px) saturate(100%) contrast(150%);

  @media screen and (max-width: 420px) {
    backdrop-filter: none;
  }
}

.requiredField {
  &:after {
    content: "*";
    color: var(--error);
    font-weight: 600;
    font-size: 1.5rem;
    padding-left: 4px;
    line-height: 0;
    box-sizing: border-box;
    position: relative;
    top: 4px;
  }
}

.scrollWrap {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 50vh;

  &::-webkit-scrollbar {
    width: .25rem;
    padding: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: rgba(54, 108, 192, 0.28);
    border-radius: .5rem;
    cursor: pointer;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #71a6ff;
    border-radius: .5rem;
  }
}

//override Buttons.
.btn, button, a {
  cursor: pointer;
  outline: none !important;
  border: none !important;
  min-width: fit-content;
}

.btn-anim-base {
  color: #fff;
  font-size: 14px !important;
  position: relative !important;
  text-decoration: none;
  overflow: hidden;
  text-transform: uppercase;
  font-weight: 500;
  min-width: 162px;
  transition: all 400ms ease-in-out, color 200ms linear;

  &:after {
    background: #ffffff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: 11;
  }

  &:hover {
    &:after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    transition: all 350ms ease-in-out;
  }

  &:active {
    color: var(--color-text) !important;
    background-color: white !important;
  }
}

.btn-anim-primary {
  @extend .btn-anim-base;
  //box-shadow: 0 0 10px 2px rgba(13, 110, 253, 0.5);

  &:hover {
    box-shadow: -5px 0 20px rgba(232, 69, 251, 0.4), 10px 0 20px 2px rgba(19, 248, 242, 0.4);
    background-color: var(--primary);
  }
}


.btn {
  --bs-btn-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-disabled-color: #fff;
  --bs-btn-active-color: #fff;
  --bs-btn-disabled-bg: var(--primary-500);
  --bs-btn-font-weight: var(--bold);
  --bs-btn-padding-x: 0.75rem; //12px
  --bs-btn-padding-y: 0.5rem; //9px
  --bs-btn-border-radius: calc(var(--unit) * 1);

  @media screen and (max-width: 576px) {
    height: 2.5rem;
    --bs-btn-padding-y: 0.25rem;
  }
}

.btn-primary.remove {
  background-color: var(--danger);
  box-shadow: var(--danger) 0 0 10px 0;
  color: #fff;
}

.btn.outline-primary {
  --bs-btn-color: var(--primary) !important;
  --bs-btn-active-color: var(--primary) !important;
  --bs-btn-hover-color: var(--primary-500) !important;
  --bs-btn-disabled-color:var(--primary-500) !important;
  --bs-btn-bg: #fff !important;
  --bs-btn-hover-bg: #fff !important;
  --bs-btn-active-bg: #fff !important;
}

.btn.btn-outline-primary {
  --bs-btn-color: var(--primary);
  --bs-btn-active-color: var(--primary);
  --bs-btn-hover-color: var(--primary-500);
  --bs-btn-disabled-color:var(--primary-500);
  --bs-btn-hover-bg: var(--primary-50);
  --bs-btn-active-bg: var(--primary-50);
}

.btn.btn-primary {
  --bs-btn-bg: var(--primary);
  --bs-btn-color: #ffff;
  --bs-btn-active-color: #ffff;
  --bs-btn-hover-color: #ffff;
  --bs-btn-disabled-color: #fff;
  --bs-btn-hover-bg: var(--primary-500);
  --bs-btn-active-bg: var(--primary-500);
}

.btn.btn-secondary {
  --bs-btn-color: var(--primary);
  --bs-btn-bg: var(--primary-50);
  --bs-btn-hover-color: var(--primary);
  --bs-btn-hover-bg: var(--primary-100);
  --bs-btn-active-bg: var(--primary-100);
  --bs-btn-disabled-color: var(--primary);
  --bs-btn-active-color: var(--primary);
  --bs-btn-disabled-bg: var(--disable-secondary)
}

.btn-default {
  background: var(--default-btn);
}

.btn-default:hover {
  background-color: #e7e7e7;
}

.btn-white {
  background-color: #fff;
  color: var(--primary);
}

.btn-white:hover {
  background-color: var(--secondary);
}

.btn-white:active {
  background-color: var(--primary-200)
}

.menu_btn {
  margin: 0;
  width: 4rem;
  height: 4rem;
  fill: var(--primary);
  padding: 1rem;

  &:hover {
    color: var(--primary);
    background-color: var(--text-100);
  }

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.btnMenuClose {
  @extend .btnClose;
  top: 0 !important;
  right: 0 !important;
  width: 3rem !important;
  height: 3rem !important;
  font-size: 1.5rem !important;
  font-weight: var(--bold);
  position: initial !important;
  margin: 0 !important;
  padding: 1rem;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.btnClose {
  position: absolute;
  right: 10px;
  top: 10px;
  margin: 5px;
  cursor: pointer;
  font-size: 24px;
  width: 32px;
  height: 32px;
  color: var(--primary);
  stroke: var(--primary);
  background-color: var(--text-50);
  border-radius: var(--br);

  &:hover {
    color: var(--primary);
    background-color: var(--text-100);
  }

  &:active {
    background-color: var(--text-200);
  }
}

.modal {
  --bs-modal-bg: #fff;

  @media screen and (max-width: 992px) {
    //--bs-modal-width: 600px
  }

  @media screen and (max-width: 576px) {
    --bs-modal-header-padding-x: 1rem;
    --bs-modal-header-padding-y: 1rem;
    --bs-modal-header-padding: 1rem;
    --bs-modal-padding: 1rem;

    .modal-footer {
      padding: 1rem;
    }

    .modal-header {
      gap: .25rem;
    }
  }
}

.list-group {
  --bs-list-group-bg: #fff;
}

.dropdown-menu {
  --bs-dropdown-bg: #ffff;
}

.form-control {
  background-color: #fff;
}

.dropdown-menu.show {
  border: none;
  box-shadow: rgba(122, 129, 139, 0.5) 0 4px 20px 0;
  display: flex;
  flex-direction: column;
}
