.select {
  cursor: pointer !important;
  font-weight: 500;
}

.groupHeaderSelect {
  padding: 5px 0;
  font-size: 22px;
  font-weight: 600;
}

.groupHeader {
  display: flex;
  border-bottom: 1px solid #c8c8c8;

  &::before {
    content: "";
    left: 0;
    width: 4px;
    background-color: rgba(255, 196, 0, 0.64);
  }
}

.groupOption + .groupOption {
  border-top: 1px solid #ededed;
}
