.authContainer {
  margin: 0 auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 320px;
  max-width: 420px;
  height: 100vh !important;

  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 3rem;
    width: 100%;
    min-height: 280px;
    background-color: white;
    border-radius: var(--br);
    -webkit-box-shadow: 0 0 20px 0 rgba(146, 172, 191, 0.2);
    -moz-box-shadow: 0 0 20px 0 rgba(146, 172, 191, 0.2);
    box-shadow: 0 0 20px 0 rgba(146, 172, 191, 0.2);

    .form {
      min-width: 260px;

      @media screen and (min-width: 720px){
        min-width: 320px;
      }
    }

    .authVariant {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;

      .variant {
        cursor: pointer;

        &:hover {
          color: var(--primary);
        }

        a {
          min-width: 60px;
          height: 42px;
        }
      }
    }
  }

  h2 {
    text-align: center;
  }

  button {
    width: 100%;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .logoCompany {
    width: 100%;
    color: var(--primary);

    img {
      height: 128px;
    }
  }
}
