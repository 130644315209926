.toolbarMenu, .baseMenu {
  display: flex;
  padding: 0;
  font-size: 1rem;
  font-weight: var(--medium);

  .link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    line-height: 1rem;
    font-weight: var(--medium);
    color: var(--color-text);
    transition: color linear 200ms;
    align-self: center;
    fill: #272727;

    svg {
      color: inherit;
      font-size: 1.5rem;
    }

    &:hover {
      color: var(--href-color);
    }
  }

  .option {
    width: 100%;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    padding: 0;
    align-items: center;

    svg {
      display: initial;
    }

    .wrapTitle {
      display: initial;
    }
  }

  .active {
    color: var(--primary);
    //-webkit-background-clip: text !important;
    //-webkit-text-fill-color: transparent;
    //background: linear-gradient(90deg, #011f3c 50%, var(--primary) 50%);
    //background-size: 200% auto;
    animation: active_anim .4s ease-in forwards;
    border-radius: var(--unit);
  }

  .wrapTitle {
  }

  .exp {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .wrapTitle {
      display: block;
      font-size: 1rem;
    }

    @media screen and (min-width: 1024px) {
      .wrapTitle {
        font-size: inherit;
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .link.option {
    flex-direction: row;
  }

  @media screen and (max-width: 480px) {
    .wrapTitle {
      font-size: .875rem !important;
    }

    .link {
      padding: .5rem;

      svg {
        font-size: 1.5rem;
      }
    }
  }
}

.toolbarMenu {
  @media screen and (max-width: 1200px) {
    .contacts-menu {
      display: none;
    }
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.baseMenu {
  .dropdownNotification {
    display: flex;
    align-self: flex-start;
  }

  @media screen and (max-width: 576px) {
    display: flex;
    gap: 1rem;

    .dropdownNotification {
      margin-top: 0;
    }
  }
}


.cartMenu {
  .amount {
    font-weight: var(--bold);
  }

  @media screen and (max-width: 767px) {
    .wrapTitle[data-is-fill='false'] {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    .wrapTitle {
      display: none !important;
    }
  }
}

[data-scroll-lock] .toolbar > .wrapElements {
  $offset: calc(var(--scrollbar-compensation) / 2);
  margin-right: $offset;
  margin-left: calc($offset * -1);
}

@-webkit-keyframes active_anim {
  to {
    background-position: -100% center;
  }
}

@-o-keyframes active_anim {
  to {
    background-position: -100% center;
  }
}

@-moz-keyframes active_anim {
  to {
    background-position: -100% center;
  }
}

@keyframes active_anim {
  to {
    background-position: -100% center;
  }
}
